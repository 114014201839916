import React from 'react'
import Layout from '../../../components/layout'
import MediaQuery from 'react-responsive'

import {
	Container,
	Section,
	Row,
	Column,
} from '../../../components/layouts'

import {
	Title,
	Paragraph,
	Input,
	Card,
} from '../../../components/ui'

import theme from '../../../theme/theme'


const moneyFormat = (num) => {
	return num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}


class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.scrolledElement = null

		this.state = {
			animateCards: false,
			contactFormSubmitted: false,
			open: true,
			totalSupporters: 5000,
			downloads: 50,
			accounts: 20,
			firstChange: false
		}

		this.handleScroll = this.handleScroll.bind(this)
		this.smoothScroll = this.smoothScroll.bind(this)
		this.onOpenModal = this.onOpenModal.bind(this)
		this.onCloseModal = this.onCloseModal.bind(this)
		this.onFunnelChange = this.onFunnelChange.bind(this)
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll)
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	}

	onOpenModal() {
		this.setState({ open: true })
	}

	onCloseModal() {
		this.setState({ open: false })
	}

	onFunnelChange(e) {
		if (!this.state.firstChange) {
			/* eslint-disable-next-line */
			analytics.track('Funnel Interaction')
			this.setState({ firstChange: true })
		}

		this.setState({ [e.target.name]: e.target.value })
	}

	handleScroll() {
		if (document.getElementById('cardsContainer')) {
			this.scrolledElement = document.getElementById('cardsContainer')

			if ((this.scrolledElement.offsetTop - (window.innerHeight / 2)) < window.scrollY) {
				this.setState({
					animateCards: true
				})
			}
		}
	}

	smoothScroll(refTo) {
		const element = document.querySelector(refTo)
		element.scrollIntoView({
			behavior: 'smooth'
		})
	}

	render() {
		const totalUsers = Math.round(this.state.totalSupporters * this.state.downloads * this.state.accounts / 10000)
		const monthlyDonations = totalUsers * 25
		const annualDonations = monthlyDonations * 12

		return (
			<Layout>
				{/* Graph Secion */}
				<MediaQuery minWidth={theme.breakPoints.small}>
					<Section style={{backgroundColor: 'white'}} id="graph" paddingTop={'6em'} paddingBottom={'3em'}>
						<Container>
							<Row>
								<Column>
									<Title fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>Just {(totalUsers / this.state.totalSupporters * 100).toFixed(2)}% of your donors...</Title>
									<Paragraph maxWidth={'100%'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2.5em'} fontWeight={'400'}>Thousands of dollars, every month.</Paragraph>
								</Column>
							</Row>
							<Row>
								<Column vAlign={'center'} maxWidth={'50%'} row>
									<Card style={{ justifyContent: 'center', height: 100, backgroundColor: theme.colors.sunglow, width: '50%' }}  marginRight={'0'}>
										<Title color={"white"} fontSize={'26px'} textAlign={'center'} marginTop={'1em'} marginBottom={'0.5em'}>${moneyFormat(monthlyDonations)}<br/>
											<Paragraph>Exp. Monthly Donations</Paragraph></Title>
									</Card>
								</Column>
								<Column vAlign={'center'} maxWidth={'50%'} row padding={50}>
									<Card style={{ justifyContent: 'center', height: 100, backgroundColor: theme.colors.crusta, width: '50%' }}  marginRight={'0'}>
										<Title color={"white"} fontSize={'26px'} textAlign={'center'} marginTop={'1em'} marginBottom={'0.5em'}>${moneyFormat(annualDonations)}<br/>
											<Paragraph>Exp. Yearly Donations</Paragraph></Title>
									</Card>
								</Column>
							</Row>
							<MediaQuery minWidth={theme.breakPoints.small}>
								<Row>
									<Column vAlign={'center'} maxWidth={'25%'} height={'350px'} row>
										<Section bg={{
											image: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Website/Graph/TotalSupporters.png',
											size: '225px 350px',
											position: 'bottom'
										}}
										paddingTop={'175px'}
										>
											<Paragraph maxWidth={'200px'} fontSize={'16px'} textAlign={'center'} marginLeft={'50px'} marginRight={'50px'} marginBottom={'0'} fontWeight={'600'}>{this.state.totalSupporters.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Paragraph>
											<Paragraph maxWidth={'200px'} fontSize={'16px'} textAlign={'center'} marginLeft={'50px'} marginRight={'50px'} marginBottom={'2.5em'} fontWeight={'400'}>total supporters reached</Paragraph>
										</Section>
									</Column>
									<Column vAlign={'center'} maxWidth={'25%'} height={'350px'} row>
										<Section bg={{
											image: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Website/Graph/downloads.png',
											size: '225px 230px',
											position: 'bottom'
										}}
										paddingTop={'225px'}
										>
											<Paragraph maxWidth={'200px'} fontSize={'16px'} textAlign={'center'} marginLeft={'50px'} marginRight={'50px'} marginBottom={'0'} fontWeight={'600'}>{this.state.downloads}%</Paragraph>
											<Paragraph maxWidth={'200px'} fontSize={'16px'} textAlign={'center'} marginLeft={'50px'} marginRight={'50px'} marginBottom={'2.5em'} fontWeight={'400'}>% who download</Paragraph>
										</Section>
									</Column>
									<Column vAlign={'center'} maxWidth={'25%'} height={'350px'} row>
										<Section bg={{
											image: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Website/Graph/accounts.png',
											size: '225px 150px',
											position: 'bottom'
										}}
										paddingTop={'240px'}
										>
											<Paragraph maxWidth={'200px'} fontSize={'16px'} textAlign={'center'} marginLeft={'50px'} marginRight={'50px'} marginBottom={'0'} fontWeight={'600'}>{this.state.accounts}%</Paragraph>
											<Paragraph maxWidth={'200px'} fontSize={'16px'} textAlign={'center'} marginLeft={'50px'} marginRight={'50px'} marginBottom={'2em'} fontWeight={'400'}>% accounts created</Paragraph>
										</Section>
									</Column>
									<Column vAlign={'center'} maxWidth={'25%'} height={'350px'} row>
										<Section bg={{
											image: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Website/Graph/avgDonation.png',
											size: '225px 100px',
											position: 'bottom'
										}}
										paddingTop={'290px'}
										>
											<Paragraph maxWidth={'200px'} fontSize={'16px'} textAlign={'center'} marginLeft={'100px'} marginRight={'100px'} marginBottom={'0'} fontWeight={'600'}>{totalUsers}</Paragraph>
											<Paragraph maxWidth={'225px'} fontSize={'16px'} textAlign={'center'} marginLeft={'25px'} marginRight={'25px'} marginBottom={'0'} fontWeight={'400'}>Flourish donors</Paragraph>
										</Section>
									</Column>
								</Row>
								<Row>
									<Column center vAlign={'center'} maxWidth={'22.75%'} row>
										<Input name="totalSupporters" onChange={this.onFunnelChange} type="number" value={this.state.totalSupporters} minLength="2" shadow autoComplete="off" required="required" />
										<Paragraph maxWidth={'100%'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2.5em'} fontWeight={'400'}>Total # Supporters</Paragraph>
									</Column>
									<Column vAlign={'center'} maxWidth={'3%'} row>
										<Paragraph fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>X</Paragraph>
									</Column>
									<Column vAlign={'center'} maxWidth={'22.75%'} row>
										<Input name="downloads" onChange={this.onFunnelChange} type="number" value={this.state.downloads} maxLength="3" shadow autoComplete="off" required="required" />
										<Paragraph maxWidth={'100%'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2.5em'} fontWeight={'400'}>% who download Flourish</Paragraph>
									</Column>
									<Column vAlign={'center'} maxWidth={'3%'} row>
										<Paragraph fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>X</Paragraph>
									</Column>
									<Column vAlign={'center'} maxWidth={'22.75%'} row>
										<Input name="accounts" onChange={this.onFunnelChange} type="number" value={this.state.accounts} shadow autoComplete="off" required="required" />
										<Paragraph maxWidth={'100%'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2.5em'} fontWeight={'400'}>% who create accounts</Paragraph>
									</Column>
									<Column vAlign={'center'} maxWidth={'3%'} row>
										<Paragraph fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>X</Paragraph>
									</Column>
									<Column vAlign={'center'} maxWidth={'22.75%'} paddingTop={'1em'} row>
										<Paragraph maxWidth={'100%'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} fontWeight={'400'}>avg. $25/month donated </Paragraph>
									</Column>
								</Row>
							</MediaQuery>
						</Container>
					</Section>
				</MediaQuery>
			</Layout>
		)
	}
}

export default IndexPage
